import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab } from '@mui/material';
import { useLocation } from 'react-router-dom';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import AllClubsPage from './AllClubsPage';
import InteractClubsPage from './InteractClubsPage';
import ClubsMapMapGL from '../modules/MapsTools/ClubsMapMapGL';

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const TabPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  let activeTab1;
  if (type === 'rotary') {
    activeTab1 = 0;
  } else if (type === 'rotaract') {
    activeTab1 = 1;
  } else if (type === 'satelite') {
    activeTab1 = 2;
  } else if (type === 'interact') {
    activeTab1 = 3;
  } else {
    activeTab1 = 0;
  }

  const [activeTab, setActiveTab] = useState(activeTab1);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        variant="scrollable" // Дозволяє скролінг табів на маленьких екранах
        scrollButtons="auto" // Відображає кнопки скролінгу при необхідності
        sx={{
          flexWrap: 'wrap', // Дозволяє табам переноситися на новий рядок
          '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap', // Переносить таби на новий рядок при потребі
          },
        }}
      >
        <Tab label="Ротарі Клуби" />
        <Tab label="Ротаракт Клуби" />
        <Tab label="Сателітні Клуби" />
        <Tab disabled label="Інтеракт Клуби" />
        <Tab label="Мапа Ротарі та Ротаракт" />
        <Tab label="Мапа Ротарі" />
        <Tab label="Мапа Ротаракт" />
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        <AllClubsPage cindex={0} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <AllClubsPage cindex={1} />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <AllClubsPage cindex={2} />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <InteractClubsPage />
      </TabPanel>
      <TabPanel value={activeTab} index={4}>
        <ClubsMapMapGL cindex={2} />
      </TabPanel>
      <TabPanel value={activeTab} index={5}>
        <ClubsMapMapGL cindex={2} filterRType='rotary' />
      </TabPanel>
      <TabPanel value={activeTab} index={6}>
        <ClubsMapMapGL cindex={2} filterRType='rotaract' />
      </TabPanel>
    </div>
  );
};

const DistrictClubsPage = () => {
  return (
    <MainPage noWarningMode={true}>
      <LandingBlock mode='light'>
        <Box className="section text-left">
          <TabPage />
        </Box>
      </LandingBlock>
    </MainPage>
  );
};

export default DistrictClubsPage;
