export const getJsonFromBdField = (advVal) => {
    let obj;

    if (typeof advVal === 'string') {
    try {
        obj = JSON.parse(advVal);
    } catch (e) {
        console.error('Invalid JSON string:', e);
        obj = {}; // або будь-яке значення за замовчуванням
    }
    } else if (typeof advVal === 'object' && advVal !== null) {
        obj = advVal;
    } else {
        obj = {}; // або будь-яке значення за замовчуванням
    }

    return obj;
}

export const changeMetafieldLine = (e, currentItem, column) => {
    const [field, subfield] = column.field.split(':');
    //console.log(`field = ${field}, subfield = ${subfield}`);
    if (!currentItem) 
        return '';
    //console.log(`advVal = ${JSON.stringify(currentItem[field])}`);
    const advVal_youtube = currentItem ? currentItem[field] : null;
    //console.log(`advVal_youtube = ${advVal_youtube}`);
    // if (!advVal_youtube) 
    //     return '';

    const obj = getJsonFromBdField(advVal_youtube);
    //console.log(`obj1 = ${JSON.stringify(obj)}`);
    obj[subfield] = e.target.value;
    //console.log(`obj2 = ${JSON.stringify(obj)}`);

    const objStr = obj;//JSON.stringify(obj);
    return objStr;
}
export const changeMetafieldLine_value = (value, currentItem, column) => {
    const [field, subfield] = column.field.split(':');
    //console.log(`field = ${field}, subfield = ${subfield}`);
    if (!currentItem) 
        return '';
    //console.log(`advVal = ${JSON.stringify(currentItem[field])}`);
    const advVal_youtube = currentItem ? currentItem[field] : null;
    //console.log(`advVal_youtube = ${advVal_youtube}`);
    // if (!advVal_youtube) 
    //     return '';

    const obj = advVal_youtube ? JSON.parse(advVal_youtube) : {};
    //console.log(`obj1 = ${JSON.stringify(obj)}`);
    obj[subfield] = value;
    //console.log(`obj2 = ${JSON.stringify(obj)}`);

    const objStr = JSON.stringify(obj);
    return objStr;
}

// advVal - null or '{ donors: 'd1'}'
export const getSubfieldValue = (advVal, metafield) => {
    //console.log(`advVal = ${JSON.stringify(advVal)}`);
    const [field, subfield] = metafield.split(':');
    if (!advVal || !advVal[field]) 
        return '';
    const advVal_youtube = advVal[field];
    //console.log(`advVal_youtube = ${advVal_youtube}`);
    if (!advVal_youtube) 
        return '';

    const obj = getJsonFromBdField(advVal_youtube);
    //console.log(`JSON = ${JSON.stringify(obj)}`);
    const str = obj[subfield];
    //console.log(`obj[${subfield}] = ${str}`);
    return str;
}
