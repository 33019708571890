import React, {useState, useEffect} from 'react';
import { Box, Container, Grid, Typography } from '@mui/material'
import {getSatelitClubs} from '../api/satelitClubs';
import './Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import TableLineComponent from '../components/TableLineComponent';

const breadcrumbs = ["Дистрикт 2232", "Сателітні Клуби"];

const tableTitles = {
    SatelliteClubName: "Назва клубу",
    SponsoringClub: "Клуб спонсор",
    //SponsoringClubPresidentEmail: "Контактна пошта",
    MemberCount: "Кількість членів в клубі",
};

const SateliteClubsPage = () => {
     
    const [data, setData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          console.log("SateliteClubsPage getting satelite clubs list");
          const clubs = await getSatelitClubs();
          setData(clubs);
          console.log("SateliteClubsPage get satelite clubs list success. const="+clubs.length);
        } catch (error) {
          console.log("SateliteClubsPage get satelite clubs list error="+error);
        }
      };
      fetchData();
    }, [])

    return (
        <MainPage noWarningMode='test' breadcrumbs={breadcrumbs} >
        <LandingBlock mode='light'>
        <Container>
            <br/>
            <Typography variant="h6">Сателітні Клуби</Typography>
            <TableLineComponent 
                data={data}
                tableTitles={tableTitles}
                addNumber
            />
            </Container>
        </LandingBlock>
        </MainPage>
    );
}

export default SateliteClubsPage;