import { useEffect, useState, useContext, useRef } from "react";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


import {LocalContext} from '../../../../tools/LanguageContextProvider';
import TykoLanding from '../../components/TykoLanding';
import LandingContent from '../../components/LandingContent';
import LandingContent_params from '../../components/LandingContent_params';
import LandingProjectPageById from '../../components/LandingProjectPageById';
import FileExplorer from '../../../../modules/FS_CMS_ukraine_org/FileExplorer/FileExplorer';
import CRUD_ClubUser from '../../rotariansBD/adminsPages/CRUD_ClubUser';
import CRUD_ClubProjects from '../../rotariansBD/adminsPages/CRUD_ClubProjects';
import CRUD_ClubProjects_multilang_eclub from '../../rotariansBD/adminsPages/CRUD_ClubProjects_multilang_eclub';
import CRUD_SiteConstructor from '../../rotariansBD/adminsPages/CRUD_SiteConstructor';
import QrGeneratorPage from '../../../../modules/QrReadder/QrGeneratorPage';
import AdminMagazines from '../../../MyAdministrations/AdminMagazines';
import AdminCommunique from '../../../MyAdministrations/AdminCommunique';
import CRUDEvents2 from '../../../RC_Cherkasy/rotariansBD/adminsPages/CRUDEvents2';
import UserCRUD from '../../../RC_Cherkasy/rotariansBD/adminsPages/UserCRUD';
import ProjectsD2232AdminPage from '../../../RC_Cherkasy/rotariansBD/adminsPages/ProjectsD2232AdminPage';
import AdminSitesEditor from '../../../MyAdministrations/AdminSitesEditor';

const adminUserDefaultFields = [
    { field: 'tags', defValue: 'rec_ukraine' },
    { field: 'club_type', defValue: 'ReC' },
    { field: 'club_name', defValue: 'Ukraine' },
    { field: 'club_name_ukr', defValue: 'Україна' },
]
const adminProjectDefaultFields = [
    { field: 'tags', defValue: 'rec_ukraine' },
    { field: 'rtype', defValue: 'ReC' },
    { field: 'rclub', defValue: 'Ukraine' },
]
const adminProjectDefaultFields_sing = [
    { field: 'tags', defValue: 'singapore' },
    { field: 'rtype', defValue: 'ReC' },
    { field: 'rclub', defValue: 'Ukraine' },
]

const site_id = 3;
const adminSitePageDefaultFields = [
    { field: 'site_id', defValue: site_id },
]
const sitePageColumns = [
    { field: 'site_id',     headerName: 'site_id', type: 'lineedit', default: site_id, tablehided: 'true' },
    { field: 'url_path',    headerName: 'url_path', type: 'lineedit', default: '' },
    { field: 'button_title',headerName: 'button_title', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'lang',        headerName: 'lang', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'page_json',   headerName: 'page_json:', type: 'json', default: '', tablehided: 'true' },
];
//---------------------------------------------------------------------
const mprojectColumns = [
    { field: 'id', headerName: 'id', type: 'id', default: '' },
    { field: 'defaultlang', headerName: 'defaultlang:', type: 'lineedit', default: '' },
    { field: 'arrlanguage', headerName: 'arrlanguage:', type: 'lineedit', default: '' },
    { field: 'tags', headerName: 'tags:', type: 'checkbox', default: '' },
];
const projectColumns = [
    { field: 'project_id', headerName: 'id', type: 'readonly', default: '' },
    { field: 'mproj_id', headerName: 'mproj_id', type: 'readonly', default: '' },
    { field: 'currlang', headerName: 'currlang', type: 'readonly', default: '' },
    { field: 'rtype', headerName: 'rtype', type: 'forcopy', default: '' },
    { field: 'rclub', headerName: 'rclub', type: 'forcopy', default: '' },
    { field: 'tags', headerName: 'tags', type: 'forcopy', default: '' },
    //{ field: 'youtube:public', headerName: 'Public:', type: 'advlineedit', default: 'false', tablehided: 'true' },

    { field: 'category', headerName: 'Rotary Area of Focus:', type: 'checkbox', default: '' },
    { field: 'category2', headerName: 'Project category:', type: 'checkbox', default: '' },
    { field: 'status', headerName: 'Realization Stage:', type: 'combo', default: '' },
    { field: 'budget', headerName: 'Budget:', type: 'combo', default: '' },
    //Organizing Clubs:
    { field: 'avatarimg', headerName: 'Primary photo', type: 'img', default: '', tablehided: 'true' },
    { field: 'imgs', headerName: 'Additional photo list:', type: 'multilineedit', default: '', tablehided: 'true' },
    { field: 'title', headerName: 'Title:', type: 'lineedit', default: '' },

    { field: 'youtube:fdesc', headerName: 'Short Description:', type: 'advmultilineedit', default: '', tablehided: 'true', tablehided: 'true' },
    { field: 'description', headerName: 'Full Description:', type: 'multilineedit', default: '', tablehided: 'true', tablehided: 'true' },
    //Rotary Showcase and Marching Grants:
    //Article:
    //Author:
    { field: 'youtube:donors', headerName: 'Donors:', type: 'advlineedit', default: '', tablehided: 'true' },
    { field: 'youtube:partners', headerName: 'Partners:', type: 'advlineedit', default: '', tablehided: 'true' },
    
    { field: 'genlink', headerName: 'Links of project: website, fb, other (by coma)', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'docs', headerName: 'Links of project documents, presentations, other (by coma)', type: 'lineedit', default: '', tablehided: 'true' },
    
    { field: 'primarycontact', headerName: 'Shared primarycontact: First name and Sirname', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'email', headerName: 'Shared contact: Email', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'phone', headerName: 'Shared contact: Phone', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'fb', headerName: 'Shared contact: Other social platforms: youtube, fb, insta (by coma)', type: 'lineedit', default: '', tablehided: 'true' },

    
    { field: 'youtube:totalbudget', headerName: 'Total Budget:', type: 'advlineedit', default: '', tablehided: 'true' },
    { field: 'youtube:currentbudget', headerName: 'Current Budget:', type: 'advlineedit', default: '', tablehided: 'true' },
];

//---------------------------------------------------------------------
const projectColumns_singapore = [
    { field: 'project_id', headerName: 'id', type: 'id', default: '' },
    { field: 'category', headerName: 'Rotary Area of Focus:', type: 'checkbox', default: '' },
    { field: 'category2', headerName: 'Project category:', type: 'checkbox', default: '' },
    { field: 'status', headerName: 'Realization Stage:', type: 'combo', default: '' },
    { field: 'budget', headerName: 'Budget:', type: 'combo', default: '' },
    
    { field: 'avatarimg', headerName: 'Primary photo', type: 'img', default: '' },
    { field: 'imgs', headerName: 'Additional photo list:', type: 'multilineedit', default: '', tablehided: 'true' },
    { field: 'title', headerName: 'Title:', type: 'lineedit', default: '' },
    { field: 'youtube:fdesc', headerName: 'Short Description:', type: 'advmultilineedit', default: '', tablehided: 'true', tablehided: 'true' },
    { field: 'description', headerName: 'Full Description:', type: 'multilineedit', default: '', tablehided: 'true', tablehided: 'true' },
    
    { field: 'genlink', headerName: 'Links of project: website, fb, other (by coma)', type: 'multilineedit', default: '', tablehided: 'true' },
    { field: 'docs', headerName: 'Links of project documents, presentations, other (by coma)', type: 'multilineedit', default: '', tablehided: 'true' },
    
    { field: 'primarycontact', headerName: 'Shared primarycontact: First name and Sirname', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'email', headerName: 'Shared contact: Email', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'phone', headerName: 'Shared contact: Phone', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'fb', headerName: 'Shared contact: Other social platforms: youtube, fb, insta (by coma)', type: 'lineedit', default: '', tablehided: 'true' },

    { field: 'youtube:thanks', headerName: 'THANKS TO OVERSEAS ROTARY MEMBER FOR PARTICIPATION IN PAST PROJECTS', type: 'advmultilineedit', default: '', tablehided: 'true' },
    { field: 'youtube:invitation', headerName: 'Invitation to the interaction of Rotarians in the project', type: 'advmultilineedit', default: '', tablehided: 'true' },
];


const root_folder = '/clubsitecreator/rd_2232';
const defaultLogo = "https://7fc65ea596c38701.cdn.express/secure/gkcKuCpwFjpmwz984fSjrg/clubsitecreator/qrcode_generator/img/Rotary District2232_trimed.png";
const defaultUlr = 'https://rotaryd2232ukraine.org';

const RcClubPageMain = ({
        designType, 
        menufunc,
        pagesContent, 
        getUserPagesContent, 
        getPagesContent_projects, 
        getAdminPage,
        tags
    }) => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    const [data_home, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data_members, setData3] = useState([]);
    const [data_admin, setData_admin] = useState([]);
    const { id } = useParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const p1 = urlParams.get('filter');
    const p2 = urlParams.get('carea');
    const careaArray = p2 && p2.split(',').map(Number);
    const hasOpened = urlParams.has('opened') ? 'opened' : null;
    const prjId = urlParams.get('prjId');

    useEffect(() => {
        const fetchData = async () => {
          try {
              await loadData(currentLocale);
          } catch (error) {
              console.log("DanatePage get data list error="+error);
          }
        };
        fetchData();
    }, [])

    useEffect(() => {
        console.log("Change curent langufge то ="+currentLocale);

        loadData(currentLocale);
    }, [currentLocale])

    const loadData = async(locale) => {
        const page = menufunc(locale);
        setNavbar(page.navbar);
        setFooter(page.footer);

        const d = await pagesContent(locale);
        setData( d );
        const d2 = await getPagesContent_projects(locale);
        setData2( d2 );
        const d3 = await getUserPagesContent(locale);
        setData3( d3 );
        const d4 = await getAdminPage(locale);
        setData_admin( d4 );


        console.log("DanatePage get data list success. const="+page.length);
    }

    return (
        <>
        {/* urlParams = {JSON.stringify(urlParams )}
        p1={p1}<br/>
        p2={p2} */}
        
            <TykoLanding 
                navbar={navbar} 
                designType={designType} 
                // mynavbar={mynavbar} 
                // noWarningMode="hide" 
                // designType={designType} 
                // footer={footer} 
                // languages={["Укр"]} 
                // userProfile={userProfile} 
                // onLogined={onLogined} 
                // isClubMember={isClubMember} 
            >
            {(() => {
                switch (id) {
                    case 'overview':
                    case '':
                        return (
                            <LandingContent data={data_home} currentLocale={currentLocale} designType={designType} />
                        );
                    case 'projects':
                        return (
                            <LandingContent_params data={data2} currentLocale={currentLocale} designType={designType} careaArray={careaArray} hasOpened={hasOpened} />
                        );
                    case 'members':
                        return (
                            <LandingContent data={data_members} currentLocale={currentLocale} designType={designType} />
                        );
                    case 'donate':
                        return (
                            <LandingContent data={null} currentLocale={currentLocale} designType={designType} />
                        );

                    case 'project':
                        return (
                            <LandingProjectPageById data={data2} prjId={prjId} currentLocale={currentLocale} designType={designType} />
                        );
                    case 'admin':
                        return (
                            <LandingContent data={data_admin} currentLocale={currentLocale} designType={designType} />
                        );
                    case 'adminmember':
                        return (
                            // <CRUD_ClubUser tags={tags} adminUserDefaultFields={adminUserDefaultFields} />
                            <UserCRUD />
                        );
                    case 'qrcodegen':
                        return (
                            <QrGeneratorPage rootFolder={root_folder} defaultLogo={defaultLogo} defaultUlr={defaultUlr} />
                        );
                    case 'adminprojects':
                        return (
                            <ProjectsD2232AdminPage />
                        );                    
                    case 'adminprojects_multi':
                      return (
                          <CRUD_ClubProjects_multilang_eclub 
                              title={'Administration for Club Projects'}
                              tags={tags} 
                              adminProjectDefaultFields={adminProjectDefaultFields} 
                              mprojectColumns={mprojectColumns}
                              projectColumns={projectColumns} 
                          />
                      ); 

                    case 'adminfiles':
                        return (
                            <div style={{padding: '24px'}}>
                                <FileExplorer rootFolder={root_folder} />
                            </div>
                        );
                    case 'adminfilesglobal':
                        return (
                            <div style={{padding: '24px'}}>
                                <FileExplorer rootFolder={'/clubsitecreator'} />
                            </div>
                        );

                    case 'adminprojects_singapore':
                        return (
                            <CRUD_ClubProjects 
                                title={'Administration for Singapore Projects Showcase'}
                                tags={"singapore"} 
                                adminemail={'t-godok@ukr.net'} 
                                adminProjectDefaultFields={adminProjectDefaultFields_sing} 
                                projectColumns={projectColumns_singapore} 
                            />
                        );   
                    case 'adminsitepage':
                        return (
                            <CRUD_SiteConstructor 
                                data={data_home} 
                                adminSitePageDefaultFields={adminSitePageDefaultFields} 
                                sitePageColumns={sitePageColumns} 
                            />
                        ); 

                    case 'adminmagazines':
                        return (
                            <div style={{padding: '24px'}}>
                               <AdminMagazines />
                            </div>
                        );

                    case 'admincommunique':
                        return (
                            <div style={{padding: '24px'}}>
                                <AdminCommunique />
                            </div>
                        );
                    
                    case 'adminevents':
                        return (
                            <div style={{padding: '24px'}}>
                                <CRUDEvents2 />
                            </div>
                        );
                    
                    case 'adminsites':
                        return (
                            <div style={{padding: '24px'}}>
                                <AdminSitesEditor />
                            </div>
                        );

                    default:
                        return data_home ? (
                            <LandingContent data={null} currentLocale={currentLocale} designType={designType} />
                        ) : (
                            "Loading..."
                        );
                }
            })()}
            </TykoLanding>
        </>
    )
};

export default RcClubPageMain;