import { LOCALES } from '../../../../i18n/locales';
export const getProjectFiltres_rec_ukraine = (local) => {
    return local===LOCALES.UKRAINIAN ? data_ukr : data_eng;
}

const data_eng = [
    {
        title: 'Rotary Area of Focus:',
        bdfield: 'category',
        items: [
            "1. Promoting peace",
            "2. Fighting disease",
            "3. Providing clean water, sanitation, and hygiene",
            "4. Saving mothers and children",
            "5. Supporting education",
            "6. Growing local economies",
            "7. Protecting the environment",
        ],
    },
    {
        title: 'Project category:',
        bdfield: 'category2',
        items: [
            "Mental Health",
            "Internally displaced people",
            "Demining",
            "Medical",
            "Community development",
            "Humanitarian",
            "Children and Education",
            "Others",
        ],
    },
    {
        title: 'Realization Stage:',
        bdfield: 'status',
        items: [
            "Offered, looking for global grant partners",
            "Offered, looking for other partners",
            "Financed and in process of realization", 
            "Permanently active",
            "Succesfuly finished",
            "During the war in Ukraine",//without for eclub
        ],
    },
    {
        title: 'Budget:',
        bdfield: 'budget',
        items: [
            "<2000$",
            //"2,000-10,000$",
            "2,000-5,000$",//without for eclub
            "5000-10,000$",//without for eclub
            "10,000-55,000$",
            ">55,000$",
        ],
    },
//     {
//         title: 'Rotary Club Type:',
//         clubtype: [
//             "Rotary",
//             "Rotaract"
//         ],
//     },
];

const data_ukr = [
    {
        title: 'Напрямки служіння Ротарі:',
        bdfield: 'category',
        items: [
            "1. Сприяння миру",
            "2. Боротьба з хворобами",
            "3. Забезпечення чистою водою, санітарне та гігієнічне забезпечення",
            "4. Підтримка матері та дитини",
            "5. Підтримка освіти",
            "6. Забезпечення зростання місцевої економіки",
            "7. Захист довкілля",
        ],
    },
    {
        title: 'Категорія проекту:',
        bdfield: 'category2',
        items: [
            "Ментальне Здоров'я",
            "Внутрішньо переміщені особи",
            "Розмінування",
            "Медицина",
            "Розвиток Громад",
            "Гуманітарні",
            "Молодь та Освіта",
            "Інше",
        ],
    },
    {
        title: 'Етап реалізації:',
        bdfield: 'status',
        items: [
            "Запропоновано, пошук партнерів глобальних грантів",
            "Запропоновано, пошук інших партнерів",
            "Проект профінансовано і реалізується", 
            "Постійний проект",
            "Успішно завершено",
        ],
    },
    {
        title: 'Бюджет:',
        bdfield: 'budget',
        items: [
            "<2000$",
            "2,000-10,000$",
            "10,000-55,000$",
            ">55,000$",
        ],
    },
//     {
//         title: 'Rotary Club Type:',
//         clubtype: [
//             "Rotary",
//             "Rotaract"
//         ],
//     },
];

const data_de = [
  {
      title: 'Rotary-Schwerpunktbereiche:',
      bdfield: 'category',
      items: [
          "1. Frieden fördern",
          "2. Krankheiten bekämpfen",
          "3. Sauberes Wasser, Sanitärversorgung und Hygiene bereitstellen",
          "4. Mütter und Kinder retten",
          "5. Bildung unterstützen",
          "6. Lokale Wirtschaften fördern",
          "7. Umwelt schützen",
      ],
  },
  {
      title: 'Projektkategorie:',
      bdfield: 'category2',
      items: [
          "Psychische Gesundheit",
          "Binnenvertriebene",
          "Minenräumung",
          "Medizinisch",
          "Gemeinschaftsentwicklung",
          "Humanitär",
          "Kinder und Bildung",
          "Andere",
      ],
  },
  {
      title: 'Umsetzungsphase:',
      bdfield: 'status',
      items: [
          "Angeboten, sucht nach globalen Grant-Partnern",
          "Angeboten, sucht nach anderen Partnern",
          "Finanziert und in der Realisierungsphase", 
          "Dauerhaft aktiv",
          "Erfolgreich abgeschlossen",
          "Während des Krieges in der Ukraine", // ohne für eClub
      ],
  },
  {
      title: 'Budget:',
      bdfield: 'budget',
      items: [
          "<2000$",
          //"2,000-10,000$",
          "2,000-5,000$", // ohne für eClub
          "5000-10,000$", // ohne für eClub
          "10,000-55,000$",
          ">55,000$",
      ],
  },
//     {
//         title: 'Rotary-Club-Typ:',
//         clubtype: [
//             "Rotary",
//             "Rotaract"
//         ],
//     },
];

const data_jp = [
  {
      title: 'ロータリーの重点分野:',
      bdfield: 'category',
      items: [
          "1. 平和の推進",
          "2. 病気との闘い",
          "3. 安全な水、衛生、衛生環境の提供",
          "4. 母子の健康を守る",
          "5. 教育の支援",
          "6. 地域経済の発展",
          "7. 環境の保護",
      ],
  },
  {
      title: 'プロジェクトカテゴリー:',
      bdfield: 'category2',
      items: [
          "メンタルヘルス",
          "国内避難民",
          "地雷除去",
          "医療",
          "地域開発",
          "人道支援",
          "子どもと教育",
          "その他",
      ],
  },
  {
      title: '実施段階:',
      bdfield: 'status',
      items: [
          "提案中、グローバル・グラント・パートナー募集中",
          "提案中、その他のパートナー募集中",
          "資金調達済み、実施中", 
          "継続的に活動中",
          "成功裏に完了",
          "ウクライナ戦争中", // eクラブを除く
      ],
  },
  {
      title: '予算:',
      bdfield: 'budget',
      items: [
          "<2000$",
          //"2,000-10,000$",
          "2,000-5,000$", // eクラブを除く
          "5000-10,000$", // eクラブを除く
          "10,000-55,000$",
          ">55,000$",
      ],
  },
//     {
//         title: 'ロータリークラブの種類:',
//         clubtype: [
//             "ロータリー",
//             "ローターアクト"
//         ],
//     },
];
