import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

const IMAGE_DURATION = 30000; // 5 секунд на кожну картинку
const PROJECT_DURATION = IMAGE_DURATION * 3; // Кожен проект 15 секунд (якщо 3 картинки)

// 🔹 Посилання на фон (Google Drive – публічний файл)
const BACKGROUND_IMAGE = "https://drive.google.com/uc?export=view&id=1l2k5F2ymKpT9F7dDQaWXQ7m9LJ0L_mFn"; // 🔄 Постійний чорний фон

const ProjectsKaleidoscope = () => {
    const [projects, setProjects] = useState([]);
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        axios.get("http://localhost:3001/api/projects")
            .then(response => {
                console.log("✅ Отримано проєкти:", response.data);
                setProjects(response.data);
            })
            .catch(error => console.error("❌ Помилка отримання проєктів:", error));
    }, []);

    useEffect(() => {
        if (projects.length === 0) return;

        const imageTimer = setInterval(() => {
            setCurrentImageIndex(prev => {
                const nextIndex = prev + 1;
                return nextIndex < projects[currentProjectIndex].imageUrls.length ? nextIndex : 0;
            });
        }, IMAGE_DURATION);

        return () => clearInterval(imageTimer);
    }, [projects, currentProjectIndex]);

    useEffect(() => {
        if (projects.length === 0) return;

        const projectTimer = setInterval(() => {
            setCurrentProjectIndex(prev => (prev + 1) % projects.length);
            setCurrentImageIndex(0);
        }, PROJECT_DURATION);

        return () => clearInterval(projectTimer);
    }, [projects]);

    if (projects.length === 0) return <Typography>Завантаження...</Typography>;

    const currentProject = projects[currentProjectIndex];
    const currentImage = currentProject.imageUrls[currentImageIndex];

    return (
        <Box
            sx={{
                position: "relative",
                width: "100vw",
                height: "100vh",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: `url(${BACKGROUND_IMAGE}) center/cover no-repeat fixed`, // 🖼 Фон завжди залишається
            }}
        >
            <AnimatePresence>
                <motion.div
                    key={currentProjectIndex}
                    initial={{ y: "100%" }}
                    animate={{ y: "0%" }}
                    exit={{ y: "-100%" }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        padding: "20px",
                        backgroundColor: "rgba(0, 0, 0, 0.7)", // 🔹 Напівпрозорий чорний фон для контенту
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            position: "absolute",
                            top: 20,
                            zIndex: 2,
                            backgroundColor: "rgba(0, 0, 0, 0.6)",
                            padding: "10px",
                            borderRadius: "8px",
                            color: "white",
                        }}
                    >
                        {currentProject.projectName}
                    </Typography>

                    {currentImage ? (
                        <motion.img
                            key={currentImageIndex}
                            src={currentImage}
                            initial={{ x: "100%" }}
                            animate={{ x: "0%" }}
                            exit={{ x: "-100%" }}
                            transition={{ duration: 1, ease: "easeInOut" }}
                            style={{
                                width: "100vw",
                                height: "100vh",
                                objectFit: "contain",
                                backgroundColor: "transparent", // 🔹 Фон зображення прозорий
                            }}
                        />
                    ) : (
                        <Typography color="white">❌ Зображення не знайдено</Typography>
                    )}
                </motion.div>
            </AnimatePresence>
        </Box>
    );
};

export default ProjectsKaleidoscope;
