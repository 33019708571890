import { RttOutlined, RttSharp } from '@mui/icons-material';
import React, { useState } from 'react';
import Box from '@mui/material/Box';

const LandingFilters2 = ({
    categories, selectedCategories, cbCategories, countCategories, 
    categories2, selectedCategories2, cbCategories2, countCategories2,
    statuses, selectedStatuses, cbStatuses, countStatuses,
    budget, selectedBudget, cbBudget, countBudget,
    clubtype, selectedRType, cbRType, countRType,
}) => {

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    if (selectedCategories.includes(value)) {
      //setSelectedCategories(selectedCategories.filter((category) => category !== value));
      cbCategories(selectedCategories.filter((category) => category !== value));
    } else {
      //setSelectedCategories([...selectedCategories, value]);
      cbCategories([...selectedCategories, value]);
    }
  };

  const handleCategory2Change = (event) => {
    const value = event.target.value;
    if (selectedCategories2.includes(value)) {
      //setSelectedCategories(selectedCategories.filter((category) => category !== value));
      cbCategories2(selectedCategories2.filter((category2) => category2 !== value));
    } else {
      //setSelectedCategories([...selectedCategories, value]);
      cbCategories2([...selectedCategories2, value]);
    }
  };

  const handleStatusesChange = (event) => {
    const value = event.target.value;
    if (selectedStatuses.includes(value)) {
      //setSelectedCategories(selectedCategories.filter((category) => category !== value));
      cbStatuses(selectedStatuses.filter((status) => status !== value));
    } else {
      //setSelectedCategories([...selectedCategories, value]);
      cbStatuses([...selectedStatuses, value]);
    }
  };

  const handleBudgetChange = (event) => {
    const value = event.target.value;
    if (selectedBudget.includes(value)) {
      //setSelectedCategories(selectedCategories.filter((category) => category !== value));
      cbBudget(selectedBudget.filter((bdg) => bdg !== value));
    } else {
      //setSelectedCategories([...selectedCategories, value]);
      cbBudget([...selectedBudget, value]);
    }
  };

  const handleRtypeChange = (event) => {
    const value = event.target.value;
    if (selectedRType.includes(value)) {
      //setSelectedCategories(selectedCategories.filter((category) => category !== value));
      cbRType(selectedRType.filter((rt) => rt !== value));
    } else {
      //setSelectedCategories([...selectedCategories, value]);
      cbRType([...selectedRType, value]);
    }
  };

  return (
    <div>
        <Box display="flex" flexDirection="row" gap={2}>
      <Box display="flex" flexDirection="column">
      <div>
        <h3>Area of focus:</h3>
        {categories && categories.map((category,index) => (
            <div>
            <label key={category}>
            <input
              type="checkbox"
              value={category}
              checked={selectedCategories.includes(category)}
              onChange={handleCategoryChange}
            />
            {category} ({countCategories[index]})
            </label>
            </div>
        ))}
      </div>
      <div>
        <h3>Project category:</h3>
        {categories2 && categories2.map((category2,index) => (
            <div>
            <label key={category2}>
            <input
              type="checkbox"
              value={category2}
              checked={selectedCategories2.includes(category2)}
              onChange={handleCategory2Change}
            />
            {category2} ({countCategories2[index]})
            </label>
            </div>
        ))}
      </div>
      </Box>
      <Box display="flex" flexDirection="column">
      <div>
        <h3>Project State:</h3>
        {statuses && statuses.map((status, index) => (
            <div>
            <label key={status}>
            <input
              type="checkbox"
              value={status}
              checked={selectedStatuses.includes(status)}
              onChange={handleStatusesChange}
            />
            {status} ({countStatuses[index]})
            </label>
            </div>
        ))}
      </div>
      
      <div>
        <h3>Budget:</h3>
        {budget && budget.map((bdg, index) => (
            <div>
            <label key={bdg}>
            <input
              type="checkbox"
              value={bdg}
              checked={selectedBudget.includes(bdg)}
              onChange={handleBudgetChange}
            />
            {bdg} ({countBudget[index]})
            </label>
            </div>
        ))}
      </div>

      {/* <div>
        <h3>Club Type:</h3>
        {clubtype && clubtype.map((rt, index) => (
            <div>
            <label key={rt}>
            <input
              type="checkbox"
              value={rt}
              checked={selectedRType.includes(rt)}
              onChange={handleRtypeChange}
            />
            {rt} ({countRType[index]})
            </label>
            </div>
        ))}
      </div> */}
      </Box>
      </Box>
    </div>
  );
};

export default LandingFilters2;
