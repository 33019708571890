export const getMenuStructure = (local) => {
    return data_eng;
}

const data_eng = {

    navbar: {
        _title: "РеК Україна",
        logoUrl: 'https://7fc65ea596c38701.cdn.express/secure/gkcKuCpwFjpmwz984fSjrg/clubsitecreator/qrcode_generator/img/Rotary District2232_trimed.png',
        // languages: [
        //     "en-US",
        //     "ua-UA",
        // ],
        // autorization: "true",
        addlogoborder: true,
        withoutd2232url: true, 
        menu: [
            {
                title: "Адміністрування",
                arr: [
                    { 
                        title: "Ротарійці Дистрикту",
                        lref: '/rd_2232/adminmember',
                    },
                    { 
                        title: "Проєкти Дистрикту",
                        lref: '/rd_2232/adminprojects',
                    },
                    { 
                        title: "Проєкти Дистрикту Мулті",
                        lref: '/rd_2232/adminprojects_multi',
                    },
                    { 
                        title: "Файлове сховище",
                        lref: '/rd_2232/adminfiles',
                    },
                    { 
                        title: "Файлове сховище Global",
                        lref: '/rd_2232/adminfilesglobal',
                    },
                    { 
                        title: "Журнал Ротарієць",
                        lref: '/rd_2232/adminmagazines',
                        disabled: true,
                    },
                    { 
                        title: "Комюніке",
                        lref: '/rd_2232/admincommunique',
                    },
                    { 
                        title: "Події Дистрикту",
                        lref: '/rd_2232/adminevents',
                    },
                    { 
                        title: "Редактор сайтів",
                        lref: '/rd_2232/adminsites',
                    },
                ]
            },
            {
                title: "Сервіси",
                arr: [
                    { 
                        title: "QR-код генератор",
                        lref: '/rd_2232/qrcodegen',
                    },
                    { 
                        title: "Avatar Maker",
                        //lref: '/rd_2232/admincommunique',
                        disabled: true,
                    },
                ]
            },
        ],
    },
    footer: {
        contactPerson_: {
            name: "Priamry contacts:",
            email: "rotaryeclubua@gmail.com",
        },
    }
}
