import { RttOutlined, RttSharp } from '@mui/icons-material';
import React, { useState } from 'react';

const LandingFilters = ({
    categories, statuses, clubtype, selectedCategories, cbCategories, selectedStatuses, 
    cbStatuses, selectedRType, cbRType, countCategories, cities, selectedCities,
    sector, selectedSector
}) => {

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    if (selectedCategories.includes(value)) {
      //setSelectedCategories(selectedCategories.filter((category) => category !== value));
      cbCategories(selectedCategories.filter((category) => category !== value));
    } else {
      //setSelectedCategories([...selectedCategories, value]);
      cbCategories([...selectedCategories, value]);
    }
  };

  const handleStatusesChange = (event) => {
    const value = event.target.value;
    if (selectedStatuses.includes(value)) {
      //setSelectedCategories(selectedCategories.filter((category) => category !== value));
      cbStatuses(selectedStatuses.filter((status) => status !== value));
    } else {
      //setSelectedCategories([...selectedCategories, value]);
      cbStatuses([...selectedStatuses, value]);
    }
  };

  const handleRtypeChange = (event) => {
    const value = event.target.value;
    if (selectedRType.includes(value)) {
      //setSelectedCategories(selectedCategories.filter((category) => category !== value));
      cbRType(selectedRType.filter((rt) => rt !== value));
    } else {
      //setSelectedCategories([...selectedCategories, value]);
      cbRType([...selectedRType, value]);
    }
  };

  return (
    <div>
      
      <div>
        <h3>Profession:</h3>
        {categories && categories.map((category,index) => (
            <div>
            <label key={category}>
            <input
              type="checkbox"
              value={category}
              checked={selectedCategories.includes(category)}
              onChange={handleCategoryChange}
            />
            {category}
            </label>
            </div>
        ))}
      </div>
      <div>
        <h3>Sector:</h3>
        {sector && sector.map((sect,index) => (
            <div>
            <label key={sect}>
            <input
              type="checkbox"
              value={sect}
              checked={selectedSector.includes(sect)}
              onChange={null}
            />
            {sect}
            </label>
            </div>
        ))}
      </div>
      <div>
        <h3>Project State:</h3>
        {statuses && statuses.map((status) => (
            <div>
            <label key={status}>
            <input
              type="checkbox"
              value={status}
              checked={selectedStatuses.includes(status)}
              onChange={handleStatusesChange}
            />
            {status}
            </label>
            </div>
        ))}
      </div>
      
      <div>
        <h3>Club Type:</h3>
        {clubtype && clubtype.map((rt) => (
            <div>
            <label key={rt}>
            <input
              type="checkbox"
              value={rt}
              checked={selectedRType.includes(rt)}
              onChange={handleRtypeChange}
            />
            {rt}
            </label>
            </div>
        ))}
      </div>
      
      <div>
        <h3>Cities:</h3>
        {cities && cities.map((rt) => (
            <div>
            <label key={rt}>
            <input
              type="checkbox"
              value={rt}
              checked={selectedCities.includes(rt)}
              //onChange={handleRtypeChange}
            />
            {rt}
            </label>
            </div>
        ))}
      </div>

    </div>
  );
};

export default LandingFilters;
