import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams, useSearchParams } from 'react-router-dom';

import {getRCLanding_stat4} from './getRCLanding_stat4';
import {getRCLanding4} from './getRCLanding4';
import {getClubPageStructure4} from './getClubPageStructure4';
import {getRCLandingHome4} from './getRCLandingHome4';
import { LOCALES } from '../../../i18n/locales';
import {LocalContext} from '../../../tools/LanguageContextProvider';

import TykoLanding from '../components/TykoLanding';
import LandingContent from '../components/LandingContent';

import LandingProjectPage_Singapore from '../components/LandingProjectPage_Singapore';

import { fetchProject } from '../rotariansBD/api/api_projects';

const RProjectsShowcase4 = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    const {id} = useParams();
    const [searchParams] = useSearchParams();
    const prid = searchParams.get('prid');

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale, id])

    const loadData = async(locale) => {
        console.log("loadData idpage="+id);
        let d = null;
        switch (id) {
            case 'browse':
                d = await getRCLanding4(locale);
                break;
    
            case 'stat':
                d = await getRCLanding_stat4(locale);
                break;
    
            case 'project':
                const dd = await fetchProject(prid, locale);
                d = dd && dd[0];
                break;

            default:
                d = await getRCLandingHome4(locale);
                break;
        }
        setData( d );

        const page = getClubPageStructure4(locale);
        setNavbar(page.navbar);
        setFooter(page.footer);

        //console.log("DanatePage get data list success. const="+d.length);
    }

    return (
        <>
        data={data && JSON.stringify(data)}
        {navbar ?
        <TykoLanding navbar={navbar} noWarningMode="hide" designType={"business"} mandatoryLanguage="Eng" >
        {id==='project' 
            ? data ? <LandingProjectPage_Singapore proj={data} /> : <>проєкта з таким ID={prid} не знайдено</>
            : data && Array.isArray(data) && <LandingContent data={data} currentLocale={currentLocale} designType={designType} footer={footer}/> 
        }
        </TykoLanding> : "Loading..."}
        </>
  );
}

export default RProjectsShowcase4;