import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';
import { limitString } from '../../../../modules/CRUD_system/functions/limitedString';

const CRUDTable = ({ data, columns, onEdit, onDelete, onExportTo, onRowClicked }) => {

    function findFieldById(columns) {
        const item = columns.find(item => item.type === 'id');
        return item ? item.field : undefined;
    }

    const field_id = findFieldById(columns);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ marginTop: '10px' }}>
                <TableHead sx={{ backgroundColor: '#e3f2fd' }}>
                    <TableRow>
                        <TableCell>№</TableCell>
                        {columns.map((column) => {
                            if (!column.tablehided) {
                                return (
                                    <TableCell key={column.field}>{column.headerName}</TableCell>
                                );
                            }
                            return null;
                        })}
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((user, index) => (
                        <TableRow 
                            key={user[field_id] || index} 
                            onClick={onRowClicked ? () => onRowClicked(index) : null}
                            sx={{ 
                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                                maxHeight: 100,
                                overflow: 'auto',
                            }} 
                        >
                            <TableCell>{index + 1}</TableCell>
                            {columns.map((column) => {
                                if (!column.tablehided) {
                                    if (column.type === 'multilineedit') {
                                        return (
                                            <TableCell key={`${user[field_id]}-${column.field}`}>
                                                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                                                    {limitString(user[column.field], 3, 30)}
                                                </Typography>
                                            </TableCell>
                                        );
                                    } else if (column.type === 'img') {
                                        return (
                                            <TableCell key={`${user[field_id]}-${column.field}`}>
                                                <img src={user[column.field]} alt={column.field} height="70px" />
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={`${user[field_id]}-${column.field}`}>
                                                {user[column.field]}
                                            </TableCell>
                                        );
                                    }
                                } else {
                                    return null;
                                }
                            })}
                            <TableCell>
                                <Button onClick={(e) => { e.stopPropagation(); onEdit(user); }}>Edit</Button>
                                <Button onClick={(e) => { e.stopPropagation(); onDelete(user[field_id]); }}>Delete</Button>
                                <Button onClick={(e) => { e.stopPropagation(); onExportTo(user[field_id]); }}>multilang</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CRUDTable;
