import axios from 'axios';

const BASE_URL = 'http://localhost:3001/api'; // Базовий URL API
const API_URL = 'https://apitools.rotaryd2232ukraine.org/api/google-sheets'; // Базовий URL API

export const fetchGoogleSheetData = async (sheetId, pageIndex = 0) => {
  try {
    const response = await axios.get(API_URL, {
      params: {
        sheet_id: sheetId,
        page_index: pageIndex,
        page_id: 0
      },
    });
    return response.data;
  } catch (error) {
    console.error('Помилка при отриманні даних із Google Sheets:', error);
    throw new Error('Не вдалося отримати дані з Google Sheets');
  }
};

export const exportProjectsGoogleSheet = async (projectsData, sheet_id) => {
  console.log('=== Exporting projects to Google Sheet ===');
  try {
      const response = await axios.post(`${BASE_URL}/export-google-sheets`, {
        sheet_id,
          data: projectsData.reverse()//.slice(0, 4).reverse()
      });
      console.log('Export successful:', response.data);
      return response.data;
  } catch (error) {
      console.error('Error exporting projects:', error);
      throw error;
  }
};